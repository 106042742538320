import {
  Input as MantineInput,
  InputWrapperProps as MantineInputWrapperProps
} from '@mantine/core';
import { CSSProperties } from 'react';

import { FCC } from '../../../fcc';
import { Box } from '../../Box';
import {
  FormInputWrapper,
  FormInputWrapperProps
} from '../InputWrapper/FormInputWrapper';

type FIWProps = FormInputWrapperProps<HTMLElement>;
export interface FormDisplayProps
  extends Pick<
    FormInputWrapperProps<HTMLElement>,
    | 'error'
    | 'hideErrorMessage'
    | 'inputContainer'
    | 'showSkeleton'
    | 'size'
    | 'descriptionProps'
    | 'labelProps'
  > {
  error?: FIWProps['error'];
  hideErrorMessage?: FIWProps['hideErrorMessage'];
  inputContainer?: FIWProps['inputContainer'];
  showSkeleton?: FIWProps['showSkeleton'];
  size?: FIWProps['size'];
  descriptionProps?: FIWProps['descriptionProps'];
  labelProps?: FIWProps['labelProps'];

  label?: MantineInputWrapperProps['label'];
  description?: MantineInputWrapperProps['description'];
  errorProps?: MantineInputWrapperProps['errorProps'];
  m?: MantineInputWrapperProps['m'];
  mt?: MantineInputWrapperProps['mt'];
  mb?: MantineInputWrapperProps['mb'];
  mx?: MantineInputWrapperProps['mx'];
  my?: MantineInputWrapperProps['my'];
  id?: MantineInputWrapperProps['id'];
  w?: MantineInputWrapperProps['w'];
  maw?: MantineInputWrapperProps['maw'];
  p?: MantineInputWrapperProps['p'];
  containerStyle?: CSSProperties;
  dataHelpId?: string;
}

export const FormDisplay: FCC<FormDisplayProps> = ({
  dataHelpId,
  children,
  containerStyle,
  descriptionProps,
  error,
  hideErrorMessage,
  labelProps,
  inputContainer,
  showSkeleton,
  size,
  id,
  ...rest
}) => {
  return (
    <FormInputWrapper
      descriptionProps={descriptionProps}
      error={error}
      hideErrorMessage={hideErrorMessage}
      labelProps={labelProps}
      inputContainer={inputContainer}
      showSkeleton={showSkeleton}
      size={size}
    >
      {(p) => (
        <MantineInput.Wrapper
          {...p}
          w="100%"
          data-help-id={dataHelpId}
          style={containerStyle}
          {...rest}
        >
          <Box w={'100%'} id={id}>
            {children}
          </Box>
        </MantineInput.Wrapper>
      )}
    </FormInputWrapper>
  );
};
