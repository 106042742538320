import { Button, ButtonProps } from '@komo-tech/ui/Button';
import { forwardRef } from 'react';

import { ButtonDataModel } from '@/common/models/ButtonDataModel';

export interface ResultButtonProps
  extends Pick<
    ButtonProps,
    | 'm'
    | 'mt'
    | 'mb'
    | 'size'
    | 'w'
    | 'leftIcon'
    | 'leftSection'
    | 'rightIcon'
    | 'rightSection'
    | 'disabled'
    | 'onClick'
    | 'showSkeleton'
    | 'style'
    | 'busy'
    | 'target'
  > {
  value: ButtonDataModel;
}

export const ResultButton = forwardRef<HTMLButtonElement, ResultButtonProps>(
  ({ value, w = '100%', size = 'md', ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        w={w}
        variant={'styled'}
        href={value.url}
        size={size}
        styledVariantProps={{
          bgColor: value.backgroundColor,
          color: value.color,
          borderColor: value.borderColor
        }}
        {...rest}
      >
        {value.text}
      </Button>
    );
  }
);
