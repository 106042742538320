import { Box } from '@komo-tech/ui/Box';
import { useWindowSize } from '@komo-tech/ui/hooks/useWindowSize';
import { Modal } from '@komo-tech/ui/Modal';
import { useTheme } from '@komo-tech/ui/theme/useTheme';
import { FCC } from 'fcc';
import { CSSProperties, ReactNode } from 'react';
import { scroller } from 'react-scroll';

import { CardModalTopNav } from '@/common/components/Card/shared/CardModalTopNav';
import { useHideEmbedHostHeaderOnMount } from '@/front/components/embedded/useHideEmbedHostHeaderOnMount';
import { useSiteModalOverlayStyle } from '@/front/components/embedded/useSiteModalOverlayStyle';

import classes from './FullPageFormModal.module.css';

interface Props {
  onClose?: () => void;
  title?: ReactNode;
  formContainerStyle?: CSSProperties;
  modalContainerStyle?: CSSProperties;
  modalBackgroundColor?: string;
  hideContent?: boolean;
  centered?: boolean;
}

export const FullPageFormModalContainer = 'full-page-form-modal--container';
export const FullPageFormModalMobileMax = 450;

export const scrollToFullPageFormElement = (id: string) => {
  scroller.scrollTo(id, {
    delay: 0,
    smooth: 'easeOutQuart',
    offset: -32,
    containerId: FullPageFormModalContainer
  });
};

export const FullPageFormModal: FCC<Props> = ({
  onClose,
  title,
  hideContent,
  formContainerStyle,
  modalContainerStyle,
  modalBackgroundColor,
  centered,
  children
}) => {
  const theme = useTheme();
  useHideEmbedHostHeaderOnMount();
  const overlayStyles = useSiteModalOverlayStyle();

  const { width } = useWindowSize();
  const isMobile = width <= FullPageFormModalMobileMax;

  return (
    <Modal
      isOpen
      size={'FullScreen'}
      modalStyles={{
        backgroundColor: hideContent
          ? 'transparent'
          : isMobile
            ? modalBackgroundColor || theme.other.colors.white
            : 'transparent'
      }}
      overlayStyles={overlayStyles}
    >
      <Box
        id={FullPageFormModalContainer}
        style={modalContainerStyle}
        bg={hideContent ? 'transparent' : undefined}
        pos={'relative'}
        w={'100%'}
        h={'100%'}
        overflow="auto"
      >
        <Box pos={'relative'} h={centered ? '100%' : undefined} mih={'100%'}>
          {!hideContent && (
            <>
              {isMobile ? null : (
                <Box
                  pos={'absolute'}
                  top={0}
                  left={0}
                  w={'100%'}
                  h={'100%'}
                  onClick={onClose}
                />
              )}
              {onClose && (
                <CardModalTopNav
                  onClose={onClose}
                  color={isMobile ? theme.colors.gray[7] : theme.colors.gray[5]}
                  disableSticky={isMobile}
                  title={title}
                  background={
                    isMobile
                      ? undefined
                      : 'linear-gradient(180deg,rgba(0, 0, 0, 0.7) 0%,rgba(0, 0, 0, 0) 94.12%)'
                  }
                />
              )}
            </>
          )}

          <Box
            className={classes.container}
            data-centered={centered}
            style={{
              ...formContainerStyle,
              background: hideContent
                ? 'transparent'
                : formContainerStyle?.background
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
