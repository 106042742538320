import {
  isInIFrame as isInIFrameFn,
  isInReactNativeWebview as isInReactNativeWebviewFn,
  ReactNativeWebViewName
} from '@komo-tech/core/utils/browser';
import {
  createContext,
  useContextSelector
} from '@komo-tech/ui/hooks/useContextSelector';
import { useEffectSkipInitial } from '@komo-tech/ui/hooks/useEffectSkipInitial';
import { FCC } from 'fcc';
import isNil from 'lodash/isNil';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { debuglog } from 'util';

import {
  EmbedClientMessages,
  EmbeddedClientMessageTypes,
  EmbeddedHostMessageTypes
} from '@/front/components/embedded/EmbeddedMessageTypes';
import { useSiteCache } from '@/front/utils/SiteCache';

type CleanupFunction = () => void;

export interface EmbedHostData {
  /**
   * The URL where the embedded app is hosted.
   */
  url: string;
  /**
   * The title of the page hosting the embedded app.
   */
  title: string;
  /**
   * The instance ID for this embedded app.
   * There could be multiple komo embeds on one page - this differentiates them.
   */
  embedId: string;
  /**
   * The URL to redirect to when a user clicks on a share link.
   */
  shareClickUrl?: string;
}

interface EmbeddedHostContextState {
  subscribe: (
    messageType: string,
    handler: (...args: any[]) => void
  ) => CleanupFunction;
  isInKomoIframe: boolean;
  isInReactNativeWebview: boolean;
  sendMessage: (data: EmbedClientMessages) => void;
  embedHostData?: EmbedHostData;
  setHideHeaderCount?: Dispatch<SetStateAction<number>>;
}

const EmbeddedHostContext = createContext<EmbeddedHostContextState | undefined>(
  undefined
);

interface Props {
  debug?: boolean;
}

export const EmbeddedHostProvider: FCC<Props> = ({
  debug = false,
  children
}) => {
  const isInIframe = useMemo(() => isInIFrameFn(), []);
  const isInReactNativeWebview = useMemo(() => isInReactNativeWebviewFn(), []);
  const [embedHostData, setEmbedHostData] = useState<EmbedHostData>(undefined);
  const setEmbeddedHostUrl = useSiteCache((x) => x.setEmbeddedHostUrl);
  const isInitialized = !!embedHostData;
  const [hideHeaderCount, setHideHeaderCount] = useState(0);

  const debugLog = (...data: any[]) => {
    if (debug) {
      console.debug(`EmbeddedHostProvider :: ${data[0]}`, ...data.slice(1));
    }
  };

  useEffect(() => {
    debugLog('is in iframe?', isInIframe);
    debugLog('is in RN WebView?', isInReactNativeWebview);

    let unsub = () => {};

    if (isInIframe || isInReactNativeWebview) {
      // request init from parent komo script, on page load
      unsub = subscribe(EmbeddedHostMessageTypes.Init, (m) => {
        setEmbedHostData((prev) => {
          if (prev === undefined && !!m) {
            if (!!m.url) {
              setEmbeddedHostUrl(m.url);
            }
            return m;
          }
          return prev;
        });
      });
    } else {
      setEmbeddedHostUrl(undefined);
    }

    sendMessage({ message: EmbeddedClientMessageTypes.RequestInit });

    // This fixes a bug where tapping outside the iframe (when embedded)
    // will result in tap events not being registered correctly
    document.addEventListener('touchend', () => {
      window.focus();
    });
    // potential fix for another bug where iframe loses input on iOS
    // https://stackoverflow.com/a/53445848/3321428
    document.addEventListener('touchstart', () => {});

    return unsub;
  }, [isInIframe, isInReactNativeWebview]);

  /**
   * Subscribe to messages from the parent script.
   * @param messageType The type of the message to handle
   * @param handler The handler function
   */
  const subscribe = (
    messageType: string,
    handler: (...args: any[]) => void
  ) => {
    if ((!isInIframe || !window) && !isInReactNativeWebview) {
      return () => {};
    }

    function handleMessageInner(e) {
      if (isNil(e.data)) return;

      let data = {} as any;
      // events sent from RN webview are string
      if (typeof e.data === 'string') {
        try {
          data = JSON.parse(e.data);
        } catch (e) {
          debuglog(e);
        }
      } else {
        data = e.data;
      }

      const message = data.message || '';
      if (!message.startsWith('komo:')) {
        debugLog('unsupported message received', message, data);
        return;
      }

      if (message === messageType) {
        debugLog('valid message to be handled', message, data);
        handler(data);
      }
    }

    window.addEventListener('message', handleMessageInner, false);

    return () =>
      window.removeEventListener('message', handleMessageInner, false);
  };

  const embedId = embedHostData?.embedId;
  const sendMessage = useCallback(
    (data: EmbedClientMessages) => {
      if ((!isInIframe || !window?.parent) && !isInReactNativeWebview) {
        return;
      }

      // Prevent sending of messages if we are not yet initialized from a parent Komo embed script.
      // E.g. if we're in a random iframe, we don't want to pump messages up (except for request init).
      if (
        !isInitialized &&
        data.message !== EmbeddedClientMessageTypes.RequestInit
      ) {
        return;
      }

      // augment messages with embedId if we have it
      if (data.embedId === undefined && embedId !== undefined) {
        data.embedId = embedId;
      }

      debugLog('sending message', data);

      if (isInReactNativeWebview) {
        window[ReactNativeWebViewName]?.postMessage(JSON.stringify(data));
        return;
      }

      window.parent.postMessage(data, '*');
    },
    [isInIframe, embedId, isInReactNativeWebview]
  );

  useEffectSkipInitial(() => {
    if (hideHeaderCount <= 0) {
      sendMessage({
        message: EmbeddedClientMessageTypes.ShowHeader,
        showHeader: true
      });
    } else {
      sendMessage({
        message: EmbeddedClientMessageTypes.ShowHeader,
        showHeader: false
      });
    }
  }, [hideHeaderCount]);

  const value = useMemo(() => {
    return {
      subscribe,
      isInKomoIframe: isInIframe && isInitialized,
      isInReactNativeWebview: isInReactNativeWebview,
      sendMessage,
      embedHostData,
      setHideHeaderCount
    };
  }, [isInIframe, embedHostData, isInReactNativeWebview]);

  return (
    <EmbeddedHostContext.Provider value={value}>
      {children}
    </EmbeddedHostContext.Provider>
  );
};

export function useUnsafeEmbeddedHostContextSelector<T>(
  selector: (value?: EmbeddedHostContextState) => T
): T | undefined {
  return useContextSelector(EmbeddedHostContext, (state) => {
    if (state === undefined) {
      return undefined;
    }
    return selector(state);
  });
}

export const useUnsafeEmbeddedHostShareInfo = () => {
  const embeddedHostUrl = useUnsafeEmbeddedHostContextSelector(
    (s) => s.embedHostData?.url
  );
  const shareClickUrl = useUnsafeEmbeddedHostContextSelector(
    (s) => s.embedHostData?.shareClickUrl
  );

  return { embeddedHostUrl, shareClickUrl };
};

/**
 * Check if the app is currently contained in an initialized Komo embed SDK iframe.
 */
export const useUnsafeIsInKomoEmbed = (): boolean => {
  const isInKomoIframe = useUnsafeEmbeddedHostContextSelector(
    (s) => s.isInKomoIframe
  );
  const isInReactNativeWebview = useUnsafeEmbeddedHostContextSelector(
    (s) => s.isInReactNativeWebview
  );
  return isInKomoIframe || isInReactNativeWebview;
};
