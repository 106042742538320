import { Guid } from '@komo-tech/core/models/Guid';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { utcDateOrUndefined } from '@komo-tech/core/utils/date';

import { FormFieldErrors } from '@/common/models/form/FormFieldErrors';

import { SiteUser } from '../SiteUser';
import { CompetitionEntryResultCodes } from './CompetitionEntryResultCodes';

export class CompetitionEntryResult {
  entryId: Guid;
  code: CompetitionEntryResultCodes = CompetitionEntryResultCodes.InvalidEntry;
  reason: string = "Couldn't connect to server, try again later.";

  get displayError() {
    switch (this.code) {
      case CompetitionEntryResultCodes.MissingField:
        return 'Please fill out all required fields.';
      default:
        return this.reason ?? 'An error occurred. Please try again.';
    }
  }
  retryAfter?: Date;
  signInRequired?: boolean;
  formFieldErrors?: FormFieldErrors[];
  gameplayId?: Guid;
  updatedUser?: SiteUser;

  constructor(props: Partial<CompetitionEntryResult>) {
    Object.assign(this, props);
    this.entryId = Guid.valueOrUndefined(this.entryId);
    this.retryAfter = utcDateOrUndefined(this.retryAfter);
    this.signInRequired = asBoolean(this.signInRequired);
    this.gameplayId = Guid.valueOrUndefined(this.gameplayId);
    if (this.updatedUser) {
      this.updatedUser = new SiteUser(this.updatedUser);
    }
  }

  get isError() {
    return !this.isSuccess && !this.hasExistingRegistration;
  }

  get isSuccess() {
    return (
      this.code === CompetitionEntryResultCodes.Successful ||
      this.code === CompetitionEntryResultCodes.ExistingRegistration
    );
  }

  get hasExistingRegistration() {
    return this.code === CompetitionEntryResultCodes.ExistingRegistration;
  }
}
