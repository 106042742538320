import { Button } from '@komo-tech/ui/Button';
import { FormPinInput } from '@komo-tech/ui/Form/PinInput';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { useFlag } from '@komo-tech/ui/hooks/useFlag';
import useTimer from '@komo-tech/ui/hooks/useTimer';
import { RefreshIcon } from '@komo-tech/ui/Icons/RefreshIcon';
import { useToaster } from '@komo-tech/ui/Toast/useToaster';
import addSeconds from 'date-fns/addSeconds';
import { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';

import {
  DynamicFormRendererHeader,
  sanitizeForDynamicFormValue
} from '@/common/components/Form/DynamicForm/Renderer';
import { SiteUser } from '@/common/models/SiteUser';
import { PublicContactsService } from '@/front/data/Contacts';

import type { CompetitionContactVerifyDrawerProps } from './_VerifyDrawer';

interface VerifyDrawerContentProps
  extends Omit<CompetitionContactVerifyDrawerProps, 'isOpen'> {}

export const VerifyDrawerContent: FC<VerifyDrawerContentProps> = ({
  site,
  fields,
  cardId,
  entryId,
  gameplayId,
  formProps,
  onCancel,
  onUserChange,
  onVerifySuccess
}) => {
  const email = useWatch({ name: 'email' });

  const handleSuccess = useEvent((user?: SiteUser) => {
    if (!user) {
      return;
    }

    if (user.hasVerifiedEmailV2) {
      if (!!formProps) {
        if (user.email !== email) {
          formProps.formMethods.setValue('email', user.email, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
          });
        }

        const formValues = formProps.formMethods.getValues();
        Object.keys(formValues || {}).forEach((k) => {
          if (k !== 'email' && user.attributes[k]) {
            const field = fields.find((f) => f.name === k);
            if (field.isHidden) return;

            const attributeValue = sanitizeForDynamicFormValue(
              user.attributes[k],
              field
            );

            formProps.formMethods.setValue(k, attributeValue, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true
            });
          }
        });
      }
      onVerifySuccess();
    }

    onUserChange(user);
  });

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const toaster = useToaster();
  const [handleResendAsync, isResending] =
    PublicContactsService.hooks.useEmailCheckHandle({
      onSuccess: ({ session }) => {
        handleSuccess(session);
      },
      onRequiresVerification: ({ email, emailSent }) => {
        if (emailSent) {
          toaster.success(`New code sent to ${email}`);
        }
      },
      delay: 500
    });

  const isResendOnCoolDownFlag = useFlag(true);
  const coolDownTimer = useTimer({
    expiryTimestamp: getCoolDownExpiry(),
    onExpire: () => isResendOnCoolDownFlag.setFalse(),
    autoStart: true
  });

  const verifyOptions = {
    onSuccess: handleSuccess,
    onFail: (e) => {
      toaster.error(e);
      setCodeError(e);
      setCode('');
    }
  };

  const [handleVerifyEmailAsync, isCheckingEmail] =
    PublicContactsService.hooks.useEmailVerifyHandle({
      ...verifyOptions
    });

  const [handleVerifyEntryAsync, isCheckingEntry] =
    PublicContactsService.hooks.useEntryVerifyHandle({
      ...verifyOptions
    });

  const handleVerifyAsync = (code: string) => {
    if (!entryId) {
      handleVerifyEmailAsync({ email, code });
    } else {
      handleVerifyEntryAsync({ email, code, cardId, entryId, gameplayId });
    }
  };

  const isReturning = !!entryId;

  const { resendCodeButton, cancelButton } = site.properties.ContactVerifyForm;
  const disabled =
    isCheckingEmail || isCheckingEntry || isResending || formProps?.disabled;
  return (
    <>
      <DynamicFormRendererHeader
        title={isReturning ? 'Welcome Back!' : 'Welcome!'}
        subTitle={
          <>
            {isReturning
              ? 'To pickup where you left off, please enter the code we sent to '
              : 'Please enter the code that we sent to '}
            <strong>{!!email ? email : 'your email'}</strong>.
          </>
        }
      />
      <FormPinInput
        size="xxl"
        length={4}
        w="100%"
        type="number"
        oneTimeCode
        autoFocus
        disabled={disabled}
        value={code}
        onChange={(v) => {
          setCode(v);
          setCodeError('');
        }}
        error={codeError}
        onComplete={handleVerifyAsync}
      />
      <Button
        variant="styled"
        w="100%"
        onClick={() => {
          void handleResendAsync({
            email,
            forceVerify: true,
            sendVerifyEmail: true
          });
          isResendOnCoolDownFlag.setTrue();
          coolDownTimer.restart(getCoolDownExpiry());
        }}
        styledVariantProps={{
          color: resendCodeButton.color,
          bgColor: resendCodeButton?.backgroundColor,
          borderColor: resendCodeButton?.borderColor
        }}
        disabled={disabled || isResendOnCoolDownFlag.value}
        busy={isResending}
        size={formProps?.fieldSize}
        leftSection={<RefreshIcon />}
      >
        {isResendOnCoolDownFlag.value
          ? `Request a new code in ${coolDownTimer.seconds}s`
          : 'Re-send code'}
      </Button>
      <Button
        variant="styled"
        w="100%"
        styledVariantProps={{
          color: cancelButton.color,
          bgColor: cancelButton?.backgroundColor,
          borderColor: cancelButton?.borderColor
        }}
        disabled={disabled}
        size={formProps?.fieldSize}
        onClick={onCancel}
      >
        Cancel
      </Button>
    </>
  );
};

const getCoolDownExpiry = () => addSeconds(new Date(), 20);
