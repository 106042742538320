import { PlayerEvent } from '@lottiefiles/react-lottie-player';
import cx from 'clsx';

import { useEvent } from '../../../hooks/useEvent';
import { useMeasure } from '../../../hooks/useMeasure';
import { Center, CenterProps } from '../../Center';
import { LottiePlayer } from '../LottiePlayer';
import { LottiePlayerProps } from '../LottiePlayer.types';
import check from './check.json';
import classes from './LottieSuccess.module.css';

const url = JSON.stringify(check);

interface Props
  extends Pick<
    LottiePlayerProps,
    'autoplay' | 'loop' | 'keepLastFrame' | 'onEvent'
  > {
  onFinish?: () => void;
  containerProps?: CenterProps;
  size?: number | string;
  minSize?: number | string;
}

export const LottieSuccess = ({
  keepLastFrame = true,
  autoplay = true,
  loop = false,
  containerProps,
  size,
  minSize = 16,
  onEvent,
  onFinish
}: Props) => {
  const handleEvent = useEvent<Props['onEvent']>((e) => {
    if (e === PlayerEvent.Complete) {
      onFinish?.();
    }

    onEvent?.(e);
  });

  const { register, data } = useMeasure();

  const { className, ...rest } = containerProps || {};

  return (
    <Center
      className={cx(classes.container, className)}
      h={size}
      mih={minSize}
      __vars={{
        '--lottie-success-container-height': `${data.bounds.height}px`
      }}
      {...rest}
      ref={register}
    >
      {data.hasBounds && (
        <LottiePlayer
          autoplay={autoplay}
          loop={loop}
          onEvent={handleEvent}
          keepLastFrame={keepLastFrame}
          src={url}
          className={classes.svg}
        />
      )}
    </Center>
  );
};
